<template>
  <div class="card">
    <div class="cash-flow-header">
      <div class="cash-flow-title">Total previsto</div>
      <div class="actions">
        <Loading
          :class="{ reload: true, loading: loading }"
          @click="() => !loading && getDashboard()"
        />
        <div class="legend">
          <div class="legend__item">
            <div class="legend__line"></div>
            Debitado
          </div>
          <div class="legend__item">
            <div class="legend__line dashed"></div>
            Previsibilidade
          </div>
        </div>
      </div>
    </div>
    <div class="chart">
      <VueApexCharts :options="options" :series="series" />
    </div>
    <div class="empty-state" v-if="!series">Não há NFS-e registrada</div>
  </div>
</template>

<script>
import moment from 'moment'
import { parseNumberToMoney } from '@/utils/moneyHelper'

export default {
  name: 'NfFlowChart',
  props: {
    dashboard: Object
  },
  components: {
    VueApexCharts: () => import('vue-apexcharts'),
    Loading: () => import('@/assets/icons/loading.svg')
  },
  async mounted() {
    this.series = this.dashboard?.forecast_total?.series
    this.options = {
      ...this.options,
      xaxis: {
        ...this.options.xaxis,
        categories: this.dashboard?.forecast_total?.categories
      }
    }
  },
  data() {
    return {
      loading: false,
      series: [],
      options: {
        chart: {
          type: 'line'
        },
        forecastDataPoints: {
          count: 6,
          fillOpacity: 0.2,
          dashArray: 4
        },
        stroke: {
          width: 3,
          curve: 'smooth'
        },
        colors: ['#00c773'],
        xaxis: {
          categories: [],
          tickAmount: 12,
          labels: {
            formatter: function (value) {
              return moment(value).format('MMM YYYY')
            }
          }
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return parseNumberToMoney(val)
            }
          }
        },
        dataLabels: {
          enabled: true,
          textAnchor: 'end',
          formatter: function (val) {
            return parseNumberToMoney(val)
          },
          style: {
            fontSize: '12px',
            fontFamily: 'Nunito Sans, sans-serif',
            fontWeight: '600'
          }
        }
      }
    }
  },
  watch: {
    dashboard: {
      handler(newVal, oldVal) {
        this.series = newVal?.forecast_total.series
        this.options = {
          ...this.options,
          xaxis: {
            ...this.options.xaxis,
            categories: newVal?.forecast_total.categories
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  height: auto;
  width: 100%;
  border-radius: 8px;
  text-align: left;
  position: relative;
  justify-content: center;
  border: 1px solid var(--neutral-200);

  .cash-flow-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    gap: 20px;

    .cash-flow-title {
      font-size: 18px;
      font-weight: 700;
      color: var(--dark-blue);
      font-family: 'Red Hat Display';
    }

    .actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      .legend {
        .legend__item {
          display: flex;
          font-size: 10px;
          font-weight: 600;
          color: var(--dark-blue);

          .legend__line {
            min-width: 30px;
            margin-right: 0.8rem;
            border-top: 3px solid var(--states-success);

            position: relative;
            top: 5px;

            &.dashed {
              border-top-color: black;
              border-top-style: dashed;
            }
          }
        }
      }
    }
  }

  .chart {
    .header {
      display: flex;
      flex-direction: row;
      padding: 20px 20px 0 20px;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      color: var(--dark-blue);
      font-size: 16px;
      font-weight: 700;
    }
  }

  .empty-state {
    width: 100%;
    height: 100%;
    font-weight: bold;
    position: absolute;
    color: var(--type-placeholder);

    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff99;
  }
}
</style>
